import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/map.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`In the subsequent three seasons of excavation, our excavations continued and explored the layers pre-dating the imposition 5th century BCE street-grid. In the early Iron Age and Archaic periods, Area C contained several occupation areas, suggested by clusters of postholes and fragmentary floor surfaces throughout the area. The Archaic and Early Iron Age activity should be understood in relationship to the Area D complex directly to the west as the two habitation clusters are likely part of one, large, elite settlement. Three infant burials, one buried in a simple ceramic vessel in the northwest corner and two along the eastern edge of the courtyard with notable grave assemblages, are also likely related to similar burials in the adjoining block. `}</MDXTag>
<MDXTag name="p" components={components}>{`Area C provides a diachronic window into elite activity at Gabii over the course of at least seven centuries. This area provides a rare continuous sequence that can be used to track changes in the habitation at the site over the transition from a series of pre-urban settlements to a proper city.`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Area C","key":"area-c","parentKey":"excavation-areas"};

  